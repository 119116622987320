nav {
    min-height: 10vh;
    background: blue;
    color: white;
}

#admin {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1em;
}

.resource {
    border-left: 2px solid lightgrey;
}